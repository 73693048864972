import type { PropsWithChildren } from 'react';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import moment from 'moment';

import { useAuthedRequest } from '~/hooks/use-authed-request';

import type { Event, FiltersState } from '~/types';
import { getDistanceBetweenTwoPoints } from '~/utils';
import { formatEvents } from '~/utils/events/format-events';
import { handleFilteringEvent } from '~/utils/events/handle-filtering-event';

import noop from 'lodash/noop';
import uniq from 'lodash/uniq';

const isFuture = (event: Event) => {
  const today = moment().startOf('day');
  const startDate = moment(event.startDate);
  const endDate = moment(event.endDate);

  return startDate.isSameOrAfter(today) || endDate.isSameOrAfter(today);
};

// Create Context Object
export const EventDashboardDataContext = createContext<{
  countries: string[];
  center: [number, number] | null;
  mapEvents: Event[];
  streamingBannerEvent?: Event;
  allEvents: Event[];
  isFiltered: boolean;
  loadingLocation: boolean;
  bounds: L.LatLngBounds | null;
  zoomLevel: number;
  setBounds: React.Dispatch<React.SetStateAction<L.LatLngBounds | null>>;
  setCenter: React.Dispatch<React.SetStateAction<[number, number] | null>>;
  setZoomLevel: React.Dispatch<React.SetStateAction<number>>;
  applyFilters: (filters: FiltersState) => void;
}>({
  bounds: null,
  isFiltered: false,
  loadingLocation: true,
  mapEvents: [],
  allEvents: [],
  countries: [],
  center: null,
  zoomLevel: 5,
  setZoomLevel: noop,
  setBounds: noop,
  setCenter: noop,
  applyFilters: noop,
});

// Create a provider for components to consume and subscribe to changes
export const EventDashboardDataProvider = ({
  children,
  events: allEvents,
  streamingBannerEvent,
}: PropsWithChildren<{ events: Event[]; streamingBannerEvent?: Event }>) => {
  const [center, setCenter] = useState<[number, number] | null>(null);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [countries, setCountries] = useState<any>(
    uniq(allEvents.map(({ countryName }: any) => countryName).filter(Boolean)).sort(),
  );
  const [mapEvents, setMapEvents] = useState<Event[]>(
    formatEvents(allEvents)
      .mapEvents?.filter(isFuture)
      .sort((a: Event, b: Event) => (moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1)) || [],
  );
  const [filters, setFilters] = useState<FiltersState>({});
  const [bounds, setBounds] = useState<L.LatLngBounds | null>(null);
  const [loadingLocation, setLoadingLocation] = useState(true);
  const initialFilters = useRef({});
  const shouldFetch = useRef(true);

  const { get } = useAuthedRequest(true);

  useEffect(() => {
    setMapEvents(
      formatEvents(allEvents)
        .mapEvents?.filter(isFuture)
        .sort((a: Event, b: Event) => (moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1)) || [],
    );
  }, [allEvents]);

  const applyFilters = (newFilters: FiltersState) => {
    setFilters(newFilters);
  };
  const load = useCallback(() => {
    get('/location')
      .then((location) => {
        setCenter(location.data);
        setLoadingLocation(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingLocation(false);
      });
  }, [get]);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      load();
    }
  }, [load]);

  useEffect(() => {
    if (JSON.stringify(initialFilters.current) !== JSON.stringify(filters)) {
      initialFilters.current = filters;
      get(
        `/events/app-filtered?organization=${filters?.organizations?.join(',')}&country=${filters.countries?.join(
          ',',
        )}`,
      )
        .then((events) => {
          const { mapEvents } = formatEvents(events.data);

          setCountries(uniq(events.data.map(({ countryName }: any) => countryName)).sort());
          setMapEvents(
            mapEvents
              .filter(isFuture)
              .sort((a: Event, b: Event) => (moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1)),
          );
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [get, filters]);

  const filteredEvents = useMemo(
    () =>
      !bounds
        ? []
        : mapEvents
            .filter((event) => bounds.contains([event.lat, event.lon]) && handleFilteringEvent(event, filters))
            .map((event) => {
              const boundsCenter = bounds.getCenter();
              return {
                ...event,
                distance: Number(
                  getDistanceBetweenTwoPoints(
                    { lat: boundsCenter.lat, lon: boundsCenter.lng },
                    { lat: event.address.center[1], lon: event.address.center[0] },
                  ),
                ),
              };
            })
            .sort((a, b) => {
              const aStart = moment(a.startDate!);
              const bStart = moment(b.startDate!);
              return aStart.isBefore(bStart) ? -1 : aStart.isSame(bStart, 'day') ? a.distance - b.distance : 1;
            }),
    [mapEvents, filters, bounds],
  );

  const isFiltered = mapEvents.length !== filteredEvents.length;

  return (
    <EventDashboardDataContext.Provider
      value={{
        bounds,
        isFiltered,
        mapEvents: filteredEvents,
        allEvents,
        countries,
        loadingLocation,
        center,
        zoomLevel,
        streamingBannerEvent,
        setCenter,
        setBounds,
        applyFilters,
        setZoomLevel,
      }}
    >
      {children}
    </EventDashboardDataContext.Provider>
  );
};
