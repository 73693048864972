import moment from 'moment';

import type { Event, FiltersState } from '~/types';

export const handleFilteringEvent = (event: Event, filters: FiltersState) => {
  const { name, type, divisions, ballType, startDate, endDate, streamUrl } = event;
  const start = moment(startDate);
  const end = moment(endDate);

  let isInStreamed = true;
  let isInType = true;
  let isInDivisions = true;
  let isInBallTypes = true;
  let isInStartDate = true;
  let isInEndDate = true;
  let isInName = true;
  let isInDistance = true;
  if (filters.type?.length) {
    isInType = filters.type.includes(type);
  }

  if (filters.divisions?.length) {
    isInDivisions = filters.divisions.some((div) => (divisions as any[]).includes(`${div}`));
  }

  if (filters.streamed) {
    isInStreamed = Boolean(
      streamUrl?.toLowerCase().includes('dodgeballttv') || streamUrl?.toLowerCase().includes('dodgeball.live'),
    );
  }

  if (filters.ballType?.length) {
    isInBallTypes = filters.ballType.some((ball) => (ballType as any[]).includes(`${ball}`));
  }

  if (filters.startDate && !filters.endDate) {
    isInStartDate =
      start.isAfter(filters.startDate, 'day') ||
      start.isSame(filters.startDate, 'day') ||
      end.isAfter(filters.startDate, 'day') ||
      end.isSame(filters.startDate, 'day');
  } else if (!filters.startDate && filters.endDate) {
    isInEndDate =
      end.isBefore(filters.endDate, 'day') ||
      end.isSame(filters.endDate, 'day') ||
      start.isBefore(filters.endDate, 'day') ||
      start.isSame(filters.endDate, 'day');
  } else if (filters.startDate && filters.endDate) {
    isInEndDate =
      (end.isBefore(filters.endDate, 'day') || end.isSame(filters.endDate, 'day')) &&
      (start.isAfter(filters.startDate, 'day') || start.isSame(filters.startDate, 'day'));
  }

  if (filters.name) {
    isInName = name.toLowerCase().includes(filters.name.toLowerCase());
  }
  return (
    isInStreamed &&
    isInType &&
    isInDivisions &&
    isInBallTypes &&
    isInStartDate &&
    isInEndDate &&
    isInName &&
    isInDistance
  );
};
