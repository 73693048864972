import moment from 'moment';

import type { Event } from '~/types';

function getDaysBetweenDates(start: Date, end: Date, dayName: string) {
  const result = [];
  const days: { [key: string]: number } = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  const day = days[dayName];
  // Copy start date
  const current = new Date(start);

  // Shift to next of required days
  current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
  // While less than end date, add dates to result array
  while (current <= end) {
    result.push(moment(new Date(current)).format('YYYY-MM-DD'));
    current.setDate(current.getDate() + 7);
  }
  return result;
}

export const formatEvents = (events: Event[]) => {
  const newEvents = events.reduce(
    (acc: { mapEvents: Event[]; calendarEvents: Event[] }, event) => {
      const { address, weekDays, startDate, endDate } = event;
      const [eLon, eLat] = address?.center || [];
      const context = address?.context || [];
      const { country, region } = address?.contextData || {};

      const [startYear, startMonth, startDay] = startDate!.split('-');
      const [endYear, endMonth, endDay] = endDate!.split('-');
      const start = new Date(Number(startYear), Number(startMonth) - 1, Number(startDay), 0, 0, 0);
      const end = new Date(Number(endYear), Number(endMonth) - 1, Number(endDay), 0, 0, 0);
      const today = moment().toDate();
      const nextWeek = moment().add(1, 'month').toDate();
      const isPast = moment(end).isBefore(moment(today), 'day');

      const contextCountry = context.find(({ id }: any) => id?.startsWith('country'));
      const contextRegion = context.find(({ id }: any) => id?.startsWith('region'));

      const additionnalProperties = {
        lon: eLon,
        lat: eLat,
        isPast,
        country: {
          name: country?.name || contextCountry?.text,
          code: country?.country_code || contextCountry?.short_code,
        },
        region: {
          name: region?.name || contextRegion?.text,
          code: region?.region_code_full || contextRegion?.short_code,
        },
      };

      if (weekDays?.length && startDate && endDate && !moment(startDate).isSame(endDate, 'day')) {
        const nextWeekDayEvents = weekDays.reduce(
          (dayAcc: Event[], day) => [
            ...dayAcc,
            ...getDaysBetweenDates(today < start ? start : today, end > nextWeek ? nextWeek : end, day).map((date) => ({
              ...event,
              startDate: date,
              endDate: date,
              originalStartDate: startDate,
              originalEndDate: endDate,
              ...additionnalProperties,
            })),
          ],
          [],
        );
        const allWeekDayEvents = weekDays.reduce(
          (dayAcc: Event[], day) => [
            ...dayAcc,
            ...getDaysBetweenDates(start, end, day).map((date) => ({
              ...event,
              startDate: date,
              endDate: date,
              originalStartDate: startDate,
              originalEndDate: endDate,
              ...additionnalProperties,
            })),
          ],
          [],
        );

        return {
          ...acc,
          mapEvents: [...acc.mapEvents, ...nextWeekDayEvents],
          calendarEvents: [...acc.calendarEvents, ...allWeekDayEvents],
        };
      }
      return {
        ...acc,
        mapEvents: [
          ...acc.mapEvents,
          {
            ...event,
            ...additionnalProperties,
          },
        ],
        calendarEvents: [
          ...acc.calendarEvents,
          {
            ...event,
            ...additionnalProperties,
          },
        ],
      };
    },
    { mapEvents: [], calendarEvents: [] },
  );

  return newEvents;
};
